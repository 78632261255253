import React, { Fragment, useContext, useEffect, useState } from "react"
import { v4 as uuid } from "uuid"
import { get, capitalize } from "lodash"
import moment from "moment"
import { P, H3, H4 } from "@4cplatform/elements/Typography"
import { Checkbox, Toggle } from "@4cplatform/elements/Forms"
import { Skeleton, ComplianceNote, Collapse } from "@4cplatform/elements/Molecules"
import { PanelBody } from "@4cplatform/elements/Molecules/FlyOutPanel"
import { Table } from "@4cplatform/elements/Organisms"
import { useTranslations } from "@4cplatform/elements/Translations"

// Helpers
import { colours } from "@4cplatform/elements/Helpers"
import { getName } from "../../../../../../../../Helpers"
import { QuotationSummaryContext } from "./context/quotationSummary.context"
import { getProductOptionHelperText } from "./quotationSummary.helpers"

// Components
import {
  EditPanelFormWrapper,
  EditFieldRow,
  FlyoutTable,
  ToggleWrapper
} from "./quotationSummary.styles"

const EditQuotePanelBody = () => {
  const t = useTranslations()
  const {
    journeyApplicantsLoading,
    selectedEditQuote,
    availableQuoteOptions,
    editQuoteFormik: formik,
    availableOptionsLoading,
    tableLoading,
    refreshQuoteLoading,
    onApplicantsChange,
    onDealCodesChange,
    showNoChildOnlyPolicyError,
    showDependantOnlyPolicyMessage,
    showPartnerPolicyMessage,
    showNoApplicantsError,
    getApplicantNameByType,
    dataQuoteTable
  } = useContext(QuotationSummaryContext)
  const [needtoSubmit, setNeedtoSubmit] = useState(false)

  useEffect(() => {
    if (needtoSubmit) {
      setNeedtoSubmit(false)
      formik.handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needtoSubmit])

  const underWritingTypeOptions = []
  const underWritingStyleOptions = []
  const paymentFrequencyOptions = [
    { label: "Monthly", value: "PAYMENT_FREQ_MONTHLY" },
    { label: "Annually", value: "PAYMENT_FREQ_YEARLY" }
  ]
  const dealCodesOptions = get(availableQuoteOptions, "usable_deal_codes", [])

  const isLoading =
    availableOptionsLoading || refreshQuoteLoading || tableLoading || journeyApplicantsLoading

  Object.keys(get(availableQuoteOptions, "available_underwriting_types", {})).forEach(option =>
    underWritingTypeOptions.push({ label: t(option), value: option })
  )

  Object.keys(get(availableQuoteOptions, "available_underwriting_styles", {})).forEach(option =>
    underWritingStyleOptions.push({ label: t(option), value: option })
  )

  const onChange = (fieldName, value) => {
    formik.setFieldTouched(fieldName, true)
    formik.setFieldValue(fieldName, value)

    if (fieldName === "options.Out-patient_Cover") {
      if (value === "Full Cover") {
        formik.setFieldValue("options.Out-patient_Full_Diagnostics", "Unlimited")
      } else if (value === "Nil") {
        formik.setFieldValue("options.Out-patient_Full_Diagnostics", "No Cover")
      }
    }

    if (fieldName === "options.Out-Patient") {
      if (["None", "Full"].includes(value)) {
        formik.setFieldValue("options.Unlimited_Out-Patient_Diagnostics", "No Cover")
      }
    }

    // we need to skip submit in order to 'formik.setFieldValue' above takes effect.
    setNeedtoSubmit(true)
  }

  const underwritingStyle = get(formik, "values.underwriting_style")
  let underwritingType = get(formik, "values.underwriting_type")
  // The underwriting type is MORI, CMORI, NMORI, select Moratorium in edit quote
  // The remains select Full Medical Underwriting
  if (underwritingStyle === "SWITCH") {
    if (["MORI", "CMORI", "NMORI"].includes(underwritingType)) {
      underwritingType = "MORI"
    } else {
      underwritingType = "FMU"
    }
  }

  const isSwitchSave = get(selectedEditQuote, "quote_type", null) === "switch_save"
  const isMoriPlus = get(selectedEditQuote, "flags.VITALITY_MORI_PLUS", false)
  const variantOptions = get(selectedEditQuote, "variant_options", {})

  const providerKey = get(selectedEditQuote, "provider.provider_key")
  const applicants = get(selectedEditQuote, "applicants")
  const isChildOnly = !applicants?.filter(applicant => applicant.rate === "adult").length ?? false

  return (
    <PanelBody isDeleted={isLoading}>
      <EditPanelFormWrapper>
        {isMoriPlus && (
          <>
            {isLoading ? (
              <Skeleton appearance="light" lineHeight="200px" />
            ) : (
              <>
                <Fragment key={uuid()}>
                  <Collapse
                    style={{ background: "#fff", padding: "5px 20px", position: "relative" }}
                    margin="0 2rem 2rem 0"
                    headerContent={
                      <>
                        <H3>Mori+ Discount Applied</H3>
                        <svg
                          viewBox="0 0 24 24"
                          data-testid="paragraph_p-helper_text-icon"
                          style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            width: "20px"
                          }}
                        >
                          <path
                            fill="#197DA4"
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                            className="sc-ftTHYK gLvenF"
                          />
                        </svg>
                      </>
                    }
                    bodyContent={
                      <>
                        <P width="90%">
                          The Mori+ that Vitality Health are offering, will allow customers to
                          receive an extra discount if they answer 'No' to the following medical
                          declaration.
                        </P>
                        <P>
                          In the last three years, has your customer or any other person to be
                          insured on this plan:
                        </P>
                        <ul style={{ color: "#335257" }}>
                          <li>Experienced symptoms, or</li>
                          <li>Received any advice from healthcare professional, or</li>
                          <li>
                            Received treatment or have treatment planned or expected (including
                            prescribed or over the counter medication)
                          </li>
                        </ul>
                      </>
                    }
                  />
                </Fragment>
              </>
            )}
          </>
        )}
        {isSwitchSave && (
          <>
            {isLoading ? (
              <Skeleton appearance="light" lineHeight="200px" />
            ) : (
              <ComplianceNote margin="0 2rem 2rem 0">
                <P width="90%">
                  Depending on when your client last claimed they could get a discount when they
                  switch of up to 10%.
                </P>
                <P>
                  If your clients have been insured for 12 consecutive months with the same insurer
                  on the product, benefits and underwriting type they are switching from - we’ll
                  match their existing cover as closely as possible with VitalityHealth. The switch
                  offer excludes health cash plans, diagnostics only plans, non-PMI modules, months
                  free offers and negotiated new business discounts with their current insurer.{" "}
                </P>
              </ComplianceNote>
            )}
            <H3 appearance="light" isLoading={isLoading} margin="0 0 2rem" colour={colours.white}>
              Matched existing cover options
            </H3>
            <table className="variantOptions" cellSpacing="20">
              <tr>
                <td colSpan="2" style={{ "font-size": "18px" }}>
                  <strong>{get(selectedEditQuote, "product_variant")}</strong>
                </td>
              </tr>
              <tr style={{ "font-size": "16px" }}>
                <td key="quoteProviderHospitalList">
                  <strong>Hospital List:</strong>
                </td>
                <td>{get(selectedEditQuote, "provider_hospital_list")}</td>
              </tr>
              {Object.keys(variantOptions).map((detail, id) => (
                <tr style={{ "font-size": "16px" }}>
                  <td key={id}>
                    <strong>{detail}:</strong>
                  </td>
                  <td>{variantOptions[detail]}</td>
                </tr>
              ))}
            </table>
          </>
        )}
        {!isSwitchSave && (
          <div>
            <H3 appearance="light" isLoading={isLoading} margin="0 0 2rem">
              Refine quote
            </H3>

            <EditFieldRow>
              {isLoading ? (
                <Skeleton appearance="light" lineHeight="5rem" />
              ) : (
                <Toggle
                  name="underwriting_style"
                  formik={formik}
                  value={underwritingStyle}
                  onChange={val => onChange("underwriting_style", val)}
                  isHorizontal
                  isRequired
                  limit={4}
                  label="Underwriting style"
                  appearance="light-inverse"
                  options={underWritingStyleOptions}
                  labelWidth="23rem"
                  margin="0 0 2rem"
                  width="100%"
                  isDisabled={isLoading || underWritingStyleOptions.length <= 1}
                  helperTitle="Underwriting style"
                  helperPosition="left"
                  helperText={
                    underWritingStyleOptions.length === 1 &&
                    getProductOptionHelperText(
                      get(selectedEditQuote, "provider.provider_key"),
                      get(selectedEditQuote, "product_name", "-"),
                      "Underwriting style"
                    )
                  }
                />
              )}
            </EditFieldRow>

            <EditFieldRow>
              {isLoading ? (
                <Skeleton appearance="light" lineHeight="5rem" />
              ) : (
                <Toggle
                  name="underwriting_type"
                  formik={formik}
                  value={underwritingType}
                  onChange={val => onChange("underwriting_type", val)}
                  isHorizontal
                  isRequired
                  limit={4}
                  label="Underwriting Type"
                  appearance="light-inverse"
                  options={underWritingTypeOptions}
                  labelWidth="23rem"
                  margin="0 0 2rem"
                  width="100%"
                  isDisabled={isLoading || underWritingTypeOptions.length <= 1}
                  helperPosition="left"
                  helperText={getProductOptionHelperText(
                    get(selectedEditQuote, "provider.provider_key"),
                    get(selectedEditQuote, "product_name", "-"),
                    "Underwriting Type"
                  )}
                  helperTitle="Underwriting Type"
                />
              )}
            </EditFieldRow>

            <EditFieldRow>
              {isLoading ? (
                <Skeleton appearance="light" lineHeight="5rem" />
              ) : (
                <Toggle
                  name="payment_frequency"
                  formik={formik}
                  value={get(formik, "values.payment_frequency")}
                  onChange={val => onChange("payment_frequency", val)}
                  isHorizontal
                  isRequired
                  limit={4}
                  label="Payment Frequency"
                  appearance="light-inverse"
                  options={paymentFrequencyOptions}
                  labelWidth="23rem"
                  margin="0 0 2rem"
                  width="100%"
                  isDisabled={isLoading || paymentFrequencyOptions.length <= 1}
                  helperPosition="left"
                  helperText={getProductOptionHelperText(
                    get(selectedEditQuote, "provider.provider_key"),
                    get(selectedEditQuote, "product_name", "-"),
                    "Payment Frequency"
                  )}
                  helperTitle="Payment frequency"
                />
              )}
            </EditFieldRow>

            {isLoading && (
              <>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
                <EditFieldRow>
                  <Skeleton appearance="light" lineHeight="5rem" />
                </EditFieldRow>
              </>
            )}

            {!isLoading &&
              availableQuoteOptions &&
              Object.keys(availableQuoteOptions?.available_options).map(quoteOption => {
                const quoteOptions = []

                const isGuidedHospitalList =
                  get(selectedEditQuote, "provider.provider_key") === "AXA" &&
                  get(selectedEditQuote, "product_name") === "PERSONALHEALTH" &&
                  get(selectedEditQuote, "hospital_list") === "Fixed List" &&
                  get(selectedEditQuote, "options", []).find(
                    option =>
                      get(option, "name") === "Guided Option" && get(option, "value") === "Yes"
                  ) &&
                  quoteOption === "Hospital List" &&
                  get(availableQuoteOptions.available_options, `${quoteOption}.length`) === 1

                get(availableQuoteOptions.available_options, quoteOption, []).forEach(option => {
                  if (option === "£0") {
                    quoteOptions.push({
                      label: "zero",
                      value: option
                    })
                  } else {
                    quoteOptions.push({
                      label: option === "Fixed" && isGuidedHospitalList ? "Guided" : t(option),
                      value: option
                    })
                  }
                })

                const isBupaAndHospitalOption =
                  get(selectedEditQuote, "provider.provider_key") === "BUPA" &&
                  (quoteOption.includes("hospital") || quoteOption.includes("Hospital"))

                const fieldName = `options.${quoteOption.split(" ").join("_")}`

                const isDisableUnlimitedOPDiagnostics =
                  fieldName === "options.Unlimited_Out-Patient_Diagnostics" &&
                  ["None", "Full"].includes(get(formik, "values.options.Out-Patient"))

                const filterQuoteOption =
                  quoteOption === "Worldwide Travel" ? "Worldwide Travel Cover" : quoteOption

                return (
                  <EditFieldRow key={uuid()}>
                    <ToggleWrapper>
                      <Toggle
                        name={fieldName}
                        formik={formik}
                        value={get(formik, `values.${fieldName}`)}
                        onChange={val => onChange(fieldName, val)}
                        isHorizontal
                        isRequired
                        limit={isBupaAndHospitalOption ? 1 : 4}
                        label={filterQuoteOption}
                        appearance="light-inverse"
                        options={quoteOptions}
                        labelWidth={isBupaAndHospitalOption ? "24rem" : "20rem"}
                        margin="0 0 2rem"
                        width="100%"
                        isDisabled={
                          isLoading || quoteOptions.length <= 1 || isDisableUnlimitedOPDiagnostics
                        }
                        helperPosition="left"
                        helperText={getProductOptionHelperText(
                          get(selectedEditQuote, "provider.provider_key"),
                          get(selectedEditQuote, "product_name", "-"),
                          quoteOption
                        )}
                        helperTitle={filterQuoteOption}
                      />
                    </ToggleWrapper>
                  </EditFieldRow>
                )
              })}

            <H3 appearance="light" isLoading={isLoading} margin="2 0 2rem 0">
              Members
            </H3>

            {showNoApplicantsError && (
              <ComplianceNote type="error">
                <H4 margin="0 0 1rem">Error</H4>
                <P margin="0">The policy must have at least 1 applicant.</P>
              </ComplianceNote>
            )}

            {showNoChildOnlyPolicyError && (
              <ComplianceNote type="error">
                <H4 margin="0 0 1rem">Child Only Quotation</H4>
                <P margin="0">This provider does not offer child only policies.</P>
              </ComplianceNote>
            )}

            {isChildOnly &&
              (providerKey === "AVIVA" || providerKey === "AXA" || providerKey === "BUPA") && (
                <ComplianceNote type="warning">
                  <H4 margin="0 0 1rem">Child Only Quotation</H4>
                  <P margin="0">
                    This is now a child only quotation so{" "}
                    {`${getApplicantNameByType("primary", "all")}`} will be the Principal Member on
                    the policy.
                  </P>
                </ComplianceNote>
              )}

            {showPartnerPolicyMessage && (
              <ComplianceNote type="warning">
                <H4 margin="0 0 1rem">Primary Applicant Removed</H4>
                <P margin="0">
                  The primary applicant has been removed from the quotation so{" "}
                  {`${getApplicantNameByType("partner", "all")}`} will be the Principal Member on
                  the policy.
                </P>
              </ComplianceNote>
            )}

            {!showPartnerPolicyMessage && showDependantOnlyPolicyMessage && (
              <ComplianceNote type="warning">
                <H4 margin="0 0 1rem">Dependant-Only Policy</H4>
                <P margin="0">
                  The quotation only includes dependants so{" "}
                  {`${getApplicantNameByType("primary", "all")}`} will be the Principal Member on
                  the policy.
                </P>
              </ComplianceNote>
            )}

            <FlyoutTable>
              <Table
                type="contained"
                data={dataQuoteTable}
                isLoading={isLoading}
                name="edit_quote_applicants"
                columns={[
                  {
                    label: "Incl.",
                    minWidth: "70px",
                    render: row => (
                      <Checkbox
                        appearance="light"
                        value={
                          !!get(formik, "values.members").filter(
                            _ => _ === get(row, "data.member_id")
                          ).length
                        }
                        onChange={val => {
                          onApplicantsChange(val, get(row, "data"))
                        }}
                        name={`include_applicant_${get(row, "data.id")}`}
                        isDisabled={isLoading}
                        margin="0"
                      />
                    )
                  },
                  {
                    label: "Name",
                    dataKey: "name",
                    minWidth: "70px",
                    render: row => getName({ data: get(row, "data") })
                  },
                  {
                    label: "Status",
                    dataKey: "status",
                    minWidth: "70px",
                    render: row => capitalize(get(row, "data.type"))
                  },
                  {
                    label: "Age",
                    dataKey: "age",
                    minWidth: "70px",
                    render: row =>
                      moment().diff(
                        moment.utc(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm").local(),
                        "years"
                      )
                  },
                  {
                    label: "Rate",
                    dataKey: "rate",
                    minWidth: "70px",
                    render: row => capitalize(get(row, "data.rate"))
                  },
                  {
                    label: "Monthly",
                    dataKey: "premiums[0].monthly_premium",
                    minWidth: "70px",
                    render: row =>
                      get(row, "data.premiums[0].monthly_premium")
                        ? `£${parseFloat(get(row, "data.premiums[0].monthly_premium")).toFixed(2)}`
                        : "-"
                  },
                  {
                    label: "Annual",
                    dataKey: "premiums[0].yearly_premium",
                    minWidth: "70px",
                    render: row =>
                      get(row, "data.premiums[0].yearly_premium")
                        ? `£${parseFloat(get(row, "data.premiums[0].yearly_premium")).toFixed(2)}`
                        : "-"
                  }
                ]}
              />
            </FlyoutTable>

            <H3 appearance="light" isLoading={isLoading} margin="2 0 2rem 0">
              Deal codes
            </H3>

            <FlyoutTable>
              <Table
                type="contained"
                data={dealCodesOptions}
                isLoading={isLoading}
                name="edit_quote_deal_codes"
                columns={[
                  {
                    label: "Incl.",
                    minWidth: "70px",
                    render: row => (
                      <Checkbox
                        appearance="light"
                        value={
                          !!get(formik, "values.deal_codes").filter(_ => _ === get(row, "data.id"))
                            .length
                        }
                        onChange={val => onDealCodesChange(val, get(row, "data.id"))}
                        name={`include_deal_code_${get(row, "data.id")}`}
                        isDisabled={isLoading}
                        margin="0"
                      />
                    )
                  },
                  {
                    label: "Type",
                    dataKey: "product_type",
                    minWidth: "70px",
                    render: row => get(row, "data.product_type")
                  },
                  [
                    {
                      dataKey: "provider",
                      minWidth: "200px",
                      render: row => get(row, "data.provider.name")
                    },
                    {
                      label: "Product",
                      dataKey: "product",
                      minWidth: "200px",
                      render: row => t(get(row, "data.product"), "-")
                    }
                  ],
                  {
                    label: "Name",
                    dataKey: "name",
                    minWidth: "250px",
                    render: row => get(row, "data.name")
                  }
                ]}
              />
            </FlyoutTable>
          </div>
        )}
      </EditPanelFormWrapper>
    </PanelBody>
  )
}

export default EditQuotePanelBody
