import React, { useReducer, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { object } from "yup"
import { get, pickBy, sortBy, isEmpty, isNil, unset, keys, flatten } from "lodash"
import { useFormik } from "formik"
import { useHistory, useParams } from "react-router-dom"
import { useGet, usePost, ApiError } from "@4cplatform/elements/Api"
import { useTranslations } from "@4cplatform/elements/Translations"
import { useMediaQuery } from "@4cplatform/elements/Hooks"
import { getBreakpoint } from "@4cplatform/elements/Helpers"

// Components
import { addAlert, clearAlerts } from "@4cplatform/elements/Alerts"
import { Provider } from "../../../UI/Templates/Journey"

// Helpers
import reducer from "./journey.reducer"
import {
  getPageData,
  mapDataToFormik,
  mapDataToYup,
  getNavigation,
  getStageParam,
  getNextStage,
  getPreviousStage,
  getPageAudit
} from "../../../UI/Helpers"

const JourneyProvider = ({ children }) => {
  // Router controls
  const { stage, reference } = useParams()
  const history = useHistory()

  const t = useTranslations()

  // State
  const [
    {
      data,
      canQuery,
      shouldRefetch,
      fieldModal,
      hasPreviousModal,
      hasSubmitModal,
      hasFaqModal,
      hasMedicalDictionaryModal,
      hasMessagingModal,
      duration,
      faqQuery,
      medicalDictionaryQuery,
      medicalDictionaryPagination,
      faqQueryResults,
      medicalDictionaryQueryResults,
      messages,
      validationSchema,
      onCustomPageSubmit,
      auditData,
      disableNext,
      pageDisclosuresNotes
    },
    dispatch
  ] = useReducer(reducer, {
    data: null,
    hasSubmitModal: false,
    hasFaqModal: false,
    hasMedicalDictionaryModal: false,
    hasMessagingModal: false,
    hasPreviousModal: false,
    fieldModal: { open: false, modal: null },
    canQuery: true,
    shouldRefetch: false,
    duration: moment(),
    faqQuery: {},
    medicalDictionaryQuery: {
      search: "",
      page: 1,
      limit: 10
    },
    medicalDictionaryPagination: {
      currentPage: 1,
      limit: 10,
      totalItems: 1,
      totalPages: 1
    },
    faqQueryResults: [],
    medicalDictionaryQueryResults: [],
    messages: [],
    validationSchema: object({}),
    onCustomPageSubmit: null,
    auditData: [],
    disableNext: false,
    pageDisclosuresNotes: []
  })

  // Journey GET request
  const {
    loading: queryLoading,
    called: queryCalled,
    error: queryError,
    refetch: queryRefetch
  } = useGet({
    endpoint: "/journeys/:reference/:stage",
    params: {
      reference,
      stage
    },
    onCompleted: res => {
      const newData = get(res, "data", {})
      dispatch({
        type: "FETCH_COMPLETE",
        data: {
          ...newData,
          page: { ...get(newData, "page", {}), ...getPageData(newData, t) }
        }
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "pageDisclosuresNotes",
        value: get(newData, "journey.policy.disclosure_notes", [])
      })
    },
    skip: !canQuery
  })

  // Every time the location changes, reset the duration.
  useEffect(() => {
    if (queryCalled && !queryLoading)
      dispatch({ type: "UPDATE_VALUE", key: "duration", value: moment() })
  }, [queryLoading, queryCalled])

  // Journey Audit POST request
  const [submitAudit, { loading: auditLoading, error: auditError }] = usePost({
    endpoint: "/journeys/:reference/audits",
    params: {
      reference
    }
  })

  // Journey POST request
  const [submit, { loading: postLoading, error: postError }] = usePost({
    endpoint: get(data, "page.route"),
    onCompleted: res => {
      // Submit Journey Audit
      const auditBody = {
        duration: moment().diff(duration, "seconds"),
        ...getPageAudit({
          journeyData: data,
          // eslint-disable-next-line no-use-before-define
          formikValues: get(formikInstance, "values"),
          auditData
        })
      }
      if (!auditBody.isMultiCall) {
        submitAudit({ body: auditBody })
      } else {
        const responses = get(auditBody, "responses", [])
        submitAudit({
          body: {
            records: responses.map(resAudit => ({
              page: auditBody.page,
              responses: auditBody.isGroupByArray ? resAudit : [resAudit],
              duration: auditBody.duration
            }))
          }
        })
      }

      // Update data in state
      const newData = get(res, "data", {})
      dispatch({
        type: "SUBMIT_COMPLETE",
        data: {
          ...newData,
          page: { ...get(newData, "page", {}), ...getPageData(newData, t) }
        }
      })
      dispatch({
        type: "UPDATE_VALUE",
        key: "pageDisclosuresNotes",
        value: get(newData, "journey.policy.disclosure_notes", [])
      })
      // Update URL
      history.push(`/journeys/${reference}/${getStageParam(get(newData, "page"))}`)
    },
    onError: () => {
      // Submit Journey Audit
      const auditBody = {
        duration: moment().diff(duration, "seconds"),
        ...getPageAudit({
          journeyData: data,
          // eslint-disable-next-line no-use-before-define
          formikValues: get(formikInstance, "values"),
          auditData
        })
      }
      submitAudit({ body: auditBody })
    }
  })

  const {
    loading: faqsLoading,
    error: faqsError,
    refetch: faqsRefetch
  } = useGet({
    endpoint: "/faqs",
    skip: !hasFaqModal,
    query: {
      ...faqQuery
    },
    onCompleted: res => {
      const newData = get(res, "data", [])
      dispatch({ type: "UPDATE_VALUE", key: "faqQueryResults", value: newData })
    },
    onError: () => {
      addAlert({
        message: t("FAQS_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const {
    loading: medicalDictionaryLoading,
    error: medicalDictionaryError,
    refetch: medicalDictionaryRefetch,
    abort: medicalDictionaryQueryAbort
  } = useGet({
    endpoint: "/medical-dictionary",
    skip: !hasMedicalDictionaryModal,
    query: {
      ...pickBy(medicalDictionaryQuery, v => v !== "" && v !== null && v !== undefined)
    },
    onCompleted: res => {
      const newData = get(res, "data", [])
      const pagination = get(res, "pagination", {
        limit: 10,
        currentPage: 1,
        totalItems: 1,
        totalPages: 1
      })
      dispatch({ type: "UPDATE_VALUE", key: "medicalDictionaryQueryResults", value: newData })
      dispatch({
        type: "UPDATE_VALUE",
        key: "medicalDictionaryPagination",
        value: pagination
      })
    },
    onError: () => {}
  })

  // Get Policy messages
  const {
    loading: messagesLoading,
    error: messagesError,
    refetch: messagesRefetch
  } = useGet({
    endpoint: "/policies/:policy/messages",
    skip: !hasMessagingModal,
    params: {
      policy: get(data, "payload.policy.slug", get(data, "journey.policy.slug"))
    },
    onCompleted: res =>
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy(get(res, "data", {}), "sent_at")
      }),
    onError: () =>
      addAlert({
        message: t("MESSAGES_INDEX_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
  })

  // Create a message
  const [createMessage, { loading: submitMessageLoading, error: submitMessageError }] = usePost({
    endpoint: "/policies/:policy/message",
    params: {
      policy: get(data, "payload.policy.slug", get(data, "journey.policy.slug"))
    },
    onCompleted: res => {
      dispatch({
        type: "UPDATE_VALUE",
        key: "messages",
        value: sortBy([...messages, res.data], "sent_at")
      })
    },
    onError: () => {
      messagesRefetch()
      addAlert({
        message: t("MESSAGE_SEND_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  })

  const submitMessage = ({ body, user }) => {
    const updatedMessages = [
      ...messages,
      {
        body_text: body.body_text,
        user_name: user
      }
    ]
    dispatch({
      type: "UPDATE_VALUE",
      key: "messages",
      value: updatedMessages
    })
    createMessage({ body })
  }

  const navigation = useMemo(() => getNavigation(data), [data])

  const setMedicalDictionaryQuery = React.useCallback(
    val => {
      medicalDictionaryQueryAbort()
      dispatch({
        type: "UPDATE_VALUE",
        key: "medicalDictionaryQuery",
        value: {
          ...medicalDictionaryQuery,
          search: val,
          page: 1
        }
      })
    },
    [dispatch, medicalDictionaryQueryAbort, medicalDictionaryQuery]
  )

  const checkPageValidation = () => {
    const flattenNavigation = flatten(navigation)
    const currentPage = flattenNavigation.find(nav => nav.key === get(data, "page.key"))
    const currentPageOrder = currentPage?.order || flattenNavigation.length
    // if has errors on previous steps
    if (
      currentPage &&
      flattenNavigation.some(
        nav =>
          nav.valid === false &&
          nav.key !== currentPage.key &&
          !isNil(nav.order) &&
          nav.order < currentPageOrder
      )
    ) {
      clearAlerts()
      addAlert({
        message:
          get(data, "page.key", "") === "ONBOARD_POLICY"
            ? t("ONBOARDER_ERROR")
            : t("QUOTATION_REVISIT_ERROR"),
        type: "error",
        dismissible: true,
        timeout: 5
      })
    }
  }

  useEffect(() => {
    dispatch({ type: "UPDATE_VALUE", key: "duration", value: moment() })
  }, [reference, stage])

  useEffect(() => {
    if (navigation?.length) {
      checkPageValidation()
    }

    // eslint-disable-next-line
  }, [navigation])

  useEffect(() => {
    if (hasMessagingModal && isEmpty(messages)) messagesRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasMessagingModal])

  useEffect(() => {
    if (data) {
      if (
        get(data, "journey.meta.pages.QUOTATION_SUMMARY", false) &&
        get(data, "page.key", "") === "QUOTE_COMPARISON"
      ) {
        unset(data, "page.sections[0].components[1]")
      }
      dispatch({ type: "UPDATE_VALUE", key: "validationSchema", value: mapDataToYup(data) })
    }
  }, [data])

  useEffect(() => {
    if (canQuery && shouldRefetch) {
      queryRefetch()
    }
  }, [canQuery, queryRefetch, shouldRefetch])
  const formatClientDetail = body => {
    const submitBody = {}
    submitBody.axa_questions = body.axa_questions

    submitBody.bupa_questions = {
      ...body.bupa_questions,
      applicant_being_covered_cancer_last_5_years: body.applicant_being_covered_cancer_last_5_years,
      applicant_had_a_heart_or_circulatory_condition_last_5_years:
        body.applicant_had_a_heart_or_circulatory_condition_last_5_years,
      applicant_had_any_arthritic_or_back_and_neck_or_spinal_condition_or_joint_replacements_last_2_years:
        body.applicant_had_any_arthritic_or_back_and_neck_or_spinal_condition_or_joint_replacements_last_2_years,
      applicants_have_any_planned_or_pending_investigations_treatment_or_surgery:
        body.applicants_have_any_planned_or_pending_investigations_treatment_or_surgery
    }

    submitBody.vitality_questions = {
      last_three_years_admission: body.last_three_years_admission,
      last_three_years_arthritis: body.last_three_years_arthritis,
      last_three_years_back_pain: body.last_three_years_back_pain,
      last_three_years_cancer: body.last_three_years_cancer,
      last_three_years_diabetes: body.last_three_years_diabetes,
      last_three_years_heart_conditions: body.last_three_years_heart_conditions,
      last_three_years_injured: body.last_three_years_injured,
      last_three_years_mental_health_issues: body.last_three_years_mental_health_issues,
      last_three_years_other: body.last_three_years_other,
      last_three_years_prostate: body.last_three_years_prostate
    }

    return submitBody
  }
  // Current page's formik instance
  const formikInstance = useFormik({
    initialValues: mapDataToFormik(data),
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: body => {
      const pageKey = get(data, "page.key")
      let submitBody = body
      if (pageKey === "MEDICAL_HISTORY") {
        submitBody = formatClientDetail(body)
      }
      return submit({
        body: get(data, "page.cleanBodyOnSubmit", false)
          ? pickBy(submitBody, v => v !== "" && v !== null && v !== undefined)
          : submitBody
      })
    }
  })

  const currentPageKey = get(data, "page.key", null)

  const disableNextFormik =
    get(data, "page.disableNextCondition.type", false) === "formik" &&
    get(formikInstance, `values.${get(data, "page.disableNextCondition.fieldKey")}`, null) ===
      get(data, "page.disableNextCondition.fieldValue")
  const disableNextData =
    get(data, "page.disableNextCondition.type", false) === "data" &&
    get(data, `${get(data, "page.disableNextCondition.path")}`, null) ===
      get(data, "page.disableNextCondition.value")

  const isNarrowScreen = useMediaQuery(
    getBreakpoint({ max: "large", returnShortString: true }),
    true
  )

  const filteredError = useMemo(() => {
    const validation = get(postError, "validation", {})
    const emailError = get(validation, "client.email_address")
    if (emailError) {
      return null
    }

    const keysValidation = keys(validation)
    if (!isEmpty(keysValidation)) {
      const firstValue = get(validation, `${keysValidation[0]}[0]`)

      if (firstValue) {
        return { message: firstValue }
      }
    }

    return postError
  }, [postError])

  const setFaqQuery = React.useCallback(
    val => {
      dispatch({ type: "UPDATE_VALUE", key: "faqQuery", value: val })
    },
    [dispatch]
  )

  const submitJourneyAudit = auditForm => {
    // Submit Journey Audit
    const auditBody = {
      duration: moment().diff(duration, "seconds"),
      ...auditForm
    }
    submitAudit({ body: auditBody })
  }

  return (
    <Provider
      value={{
        data,
        refetchData: () => dispatch({ type: "REFETCH" }),
        submitJourneyAudit,
        formik: { ...formikInstance, validationSchema },
        pageDisclosuresNotes,
        onUpdatePageDisclosuresNotes: val =>
          dispatch({ type: "UPDATE_VALUE", key: "pageDisclosuresNotes", value: val }),
        onPageSubmit: formikInstance.handleSubmit,
        onCustomPageSubmit,
        setCustomPageSubmit: val =>
          dispatch({ type: "UPDATE_VALUE", key: "onCustomPageSubmit", value: val }),
        auditData,
        updateJourneyAuditData: val =>
          dispatch({ type: "UPDATE_VALUE", key: "auditData", value: val }),
        postError,
        navigation,
        duration,
        resetDuration: () => dispatch({ type: "UPDATE_VALUE", key: "duration", value: moment() }),
        isNavigationCollapsed:
          isNarrowScreen ||
          currentPageKey === "HOSPITAL_PREFERENCE" ||
          currentPageKey === "QUOTE_COMPARISON" ||
          currentPageKey === "QUOTATION_SUMMARY",
        onNavClick: page => {
          // change the params and expose the query
          history.push(`/journeys/${reference}/${getStageParam(page)}`)
          dispatch({ type: "CHANGE_PAGE" })
        },
        onClickNext: () => {
          // Change the params and expose the query
          const newStage = getNextStage(data)
          if (!(newStage === stage)) {
            history.push(`/journeys/${reference}/${newStage}`)
            dispatch({ type: "CHANGE_PAGE" })
          }
        },
        hideNext: currentPageKey === "ONBOARD_POLICY" || currentPageKey === "VITALITY_PORTAL",
        disableNext:
          disableNext ||
          disableNextFormik ||
          disableNextData ||
          (currentPageKey === "SUBMIT_TO_PROVIDER" &&
            !["ACCEPTED_UNDERWRITING", "ACCEPTED_UNDERWRITING_WITH_EXCLUSIONS"].includes(
              get(data, "payload.policy.status", get(data, "journey.policy.status", null))
            )),
        setDisableNext: value => dispatch({ type: "UPDATE_VALUE", key: "disableNext", value }),
        onClickPrevious: () => {
          // Change the params and expose the query
          const newStage = getPreviousStage(data)
          if (!(newStage === stage)) {
            history.push(`/journeys/${reference}/${newStage}`)
            dispatch({ type: "CHANGE_PAGE" })
          }
        },
        hidePrevious: getPreviousStage(data) === stage || !getPreviousStage(data),
        hasSubmitModal,
        setSubmitModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "hasSubmitModal", value: val }),
        hasPreviousModal,
        setPreviousModal: val =>
          dispatch({ type: "UPDATE_VALUE", key: "hasPreviousModal", value: val }),
        fieldModal,
        setFieldModal: val => dispatch({ type: "UPDATE_VALUE", key: "fieldModal", value: val }),
        hasFaqModal,
        setFaqModal: val => {
          dispatch({ type: "UPDATE_VALUE", key: "hasFaqModal", value: val })
          if (val === true)
            dispatch({
              type: "UPDATE_VALUE",
              key: "faqQuery",
              value: {
                search: "",
                provider: ""
              }
            })
          if (val === false) {
            dispatch({ type: "UPDATE_VALUE", key: "faqQueryResults", value: [] })
            dispatch({
              type: "UPDATE_VALUE",
              key: "faqQuery",
              value: {}
            })
          }
        },
        faqQuery,
        faqQueryResults,
        faqsLoading,
        faqsRefetch,
        setFaqQuery,
        hasMessagingModal,
        setMessagingModal: value => {
          dispatch({ type: "UPDATE_VALUE", key: "hasMessagingModal", value })
          if (!value) dispatch({ type: "UPDATE_VALUE", key: "messages", value: [] })
        },
        messages,
        messagesLoading,
        messagesRefetch,
        submitMessage,
        submitMessageLoading,
        hasMedicalDictionaryModal,
        setMedicalDictionaryModal: val => {
          dispatch({ type: "UPDATE_VALUE", key: "hasMedicalDictionaryModal", value: val })
          if (val === true) {
            dispatch({
              type: "UPDATE_VALUE",
              key: "medicalDictionaryQuery",
              value: {
                limit: 10,
                page: 1,
                search: ""
              }
            })
            dispatch({
              type: "UPDATE_VALUE",
              key: "medicalDictionaryPagination",
              value: {
                currentPage: 1,
                limit: 10,
                totalItems: 1,
                totalPages: 1
              }
            })
          }
          if (val === false) {
            dispatch({ type: "UPDATE_VALUE", key: "medicalDictionaryQueryResults", value: [] })
            dispatch({
              type: "UPDATE_VALUE",
              key: "medicalDictionaryQuery",
              value: {}
            })
          }
        },
        medicalDictionaryQuery,
        medicalDictionaryQueryResults,
        medicalDictionaryPagination,
        handleMedicalDictionaryPagination: nextPage =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "medicalDictionaryQuery",
            value: {
              ...medicalDictionaryQuery,
              page: nextPage
            }
          }),
        medicalDictionaryLoading,
        medicalDictionaryRefetch,
        setMedicalDictionaryQuery,
        isLoading: queryLoading || postLoading,
        nextLoading: postLoading,
        auditLoading,
        addToFormikValidationSchema: schemaObject =>
          dispatch({
            type: "UPDATE_VALUE",
            key: "validationSchema",
            value: validationSchema.concat(schemaObject)
          }),
        removeFromFormikValidationSchema: property => {
          const { [property]: value, ...theRest } = validationSchema.fields
          const newNodesArr = validationSchema._nodes.filter(i => i !== property.toString())
          validationSchema.fields = theRest
          validationSchema._nodes = newNodesArr
          dispatch({
            type: "UPDATE_VALUE",
            key: "validationSchema",
            value: validationSchema
          })
        }
      }}
    >
      {children}
      <ApiError
        error={
          queryError ||
          filteredError ||
          auditError ||
          faqsError ||
          medicalDictionaryError ||
          messagesError ||
          submitMessageError
        }
      />
    </Provider>
  )
}

JourneyProvider.propTypes = {
  children: PropTypes.any
}

export default JourneyProvider
