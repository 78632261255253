/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react"
import { capitalize, get } from "lodash"
import { Table } from "@4cplatform/elements/Organisms"

// Helpers
import { ConfigContext } from "@4cplatform/elements/Config"
import { Toggle } from "@4cplatform/elements/Forms"
import moment from "moment/moment"
import { P, SmallText } from "@4cplatform/elements/Typography"
import { Modal } from "@4cplatform/elements/Molecules"
import { JourneyContext } from "../../../../../../journey.context"
import { getName } from "../../../../../../../../Helpers"

const AxaQuestionsTable = () => {
  const { GLOBAL_TITLES } = useContext(ConfigContext)
  const { formik, data } = useContext(JourneyContext)

  const isLocked = get(data, "journey.locked", false)
  const applicants = get(data, "journey.applicants", [])
  const [openMentalIllnessModal, setOpenMentalIllnessModal] = useState(false)
  const [openCancerModal, setOpenCancerModal] = useState(false)

  useEffect(() => {
    const axaQuestions = get(formik.values, "axa_questions", null)

    if (axaQuestions === null) {
      applicants.forEach(applicant => {
        const applicantId = get(applicant, "id")

        const fields = [
          "last_5_years_heart_condition_or_heart_problem",
          "last_5_years_stroke",
          "last_5_years_cancer",
          "last_5_years_diabetes",
          "last_5_years_mental_illness",
          "planned_or_pending_treatment_consultations_investigations_diagnostic_tests",
          "last_12_months_treatment_in_hospital_or_consulted_specialist"
        ]

        fields.forEach(field => {
          const applicantFieldRef = `axa_questions.applicant_${applicantId}.${field}`

          formik.setFieldValue(applicantFieldRef, get(applicant.answers, field, false))
        })
      })
    }
  }, [formik.values])

  const applicantsLoading = false

  return (
    <>
      <Table
        data={applicants}
        isLoading={applicantsLoading}
        columns={[
          [
            {
              label: "Name",
              data: "first_name",
              minWidth: "180px",
              render: row =>
                getName({
                  data: get(row, "data"),
                  hasTitle: true,
                  globalTitles: GLOBAL_TITLES,
                  hideOtherTitle: true
                })
            },
            {
              label: "Details",
              data: "date_of_birth",
              minWidth: "180px",
              render: row => {
                const dob = moment(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm")
                const age = moment().diff(dob, "years")
                return (
                  <SmallText margin="0">
                    Age {age}
                    &nbsp;&nbsp;
                    {capitalize(get(row, "data.type", "-"))}
                  </SmallText>
                )
              }
            }
          ],
          {
            label: "Heart condition or heart problem*",
            data: "axa_questions.last_5_years_heart_condition_or_heart_problem",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_5_years_heart_condition_or_heart_problem`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.last_5_years_heart_condition_or_heart_problem", false)
                    )}
                  />
                </div>
              )
            }
          },
          {
            label: "Stroke*",
            data: "data.stroke",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_5_years_stroke`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.last_5_years_stroke", false)
                    )}
                  />
                </div>
              )
            }
          },
          {
            label: "Cancer*",
            data: "data.cancer",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_5_years_cancer`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      if (val) {
                        setOpenCancerModal(true)
                      }
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.last_5_years_cancer", false)
                    )}
                  />
                </div>
              )
            }
          },
          {
            label: "Diabetes*",
            data: "data.diabetes",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_5_years_diabetes`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.last_5_years_diabetes", false)
                    )}
                  />
                </div>
              )
            }
          },
          {
            label: "Mental illness*",
            data: "data.mental_illness",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_5_years_mental_illness`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      if (val) {
                        setOpenMentalIllnessModal(true)
                      }
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(row, "data.answers.last_5_years_mental_illness", false)
                    )}
                  />
                </div>
              )
            }
          }
        ]}
        name="applicants"
        hasActions={false}
        hasPerPage={false}
      />
      <br />
      <Table
        data={applicants}
        isLoading={applicantsLoading}
        columns={[
          [
            {
              label: "Name",
              data: "first_name",
              minWidth: "180px",
              render: row =>
                getName({
                  data: get(row, "data"),
                  hasTitle: true,
                  globalTitles: GLOBAL_TITLES,
                  hideOtherTitle: true
                })
            },
            {
              label: "Details",
              data: "date_of_birth",
              minWidth: "180px",
              render: row => {
                const dob = moment(get(row, "data.date_of_birth"), "YYYY-MM-DD HH:mm")
                const age = moment().diff(dob, "years")
                return (
                  <SmallText margin="0">
                    Age {age}
                    &nbsp;&nbsp;
                    {capitalize(get(row, "data.type", "-"))}
                  </SmallText>
                )
              }
            }
          ],
          {
            label:
              "Have you or anyone to be insured on this policy had treatment in hospital or consulted a specialist in the last 12 months?*",
            data: "axa_questions.last_12_months_treatment_in_hospital_or_consulted_specialist",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.last_12_months_treatment_in_hospital_or_consulted_specialist`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(
                        row,
                        "data.answers.last_12_months_treatment_in_hospital_or_consulted_specialist",
                        false
                      )
                    )}
                  />
                </div>
              )
            }
          },
          {
            label:
              "Do you or anyone to be insured on this policy have any treatment, consultations, investigations or diagnostic tests, planned or pending?*",
            data: "axa_questions.planned_or_pending_treatment_consultations_investigations_diagnostic_tests",
            minWidth: "80px",
            render: row => {
              const applicantId = get(row, "data.id", "")
              const applicantFieldRef = `axa_questions.applicant_${applicantId}.planned_or_pending_treatment_consultations_investigations_diagnostic_tests`

              return (
                <div style={{ marginTop: "20px" }}>
                  <Toggle
                    name={applicantFieldRef}
                    options={[
                      { order: 1, label: "No", value: false },
                      { order: 2, label: "Yes", value: true }
                    ]}
                    errorPrefix="none"
                    isHorizontal
                    labelWidth="39.5rem"
                    margin="0 0 2rem"
                    isRequired
                    isDisabled={isLocked}
                    onChange={val => {
                      formik.setFieldValue(applicantFieldRef, val)
                    }}
                    value={get(
                      formik.values,
                      applicantFieldRef,
                      get(
                        row,
                        "data.answers.planned_or_pending_treatment_consultations_investigations_diagnostic_tests",
                        false
                      )
                    )}
                  />
                </div>
              )
            }
          }
        ]}
        name="applicants"
        hasActions={false}
        hasPerPage={false}
      />

      {openCancerModal && (
        <Modal title="Cancer" hasHeader="true" onClose={() => setOpenCancerModal(false)}>
          <P>
            Please be advised by answering YES to this question, your client will not be eligible
            for Full Cancer Cover for any AXA Health policies and will only be eligible for NHS
            Cancer Support.
          </P>
        </Modal>
      )}
      {openMentalIllnessModal && (
        <Modal
          title="Mental Illness"
          hasHeader="true"
          onClose={() => setOpenMentalIllnessModal(false)}
        >
          <P>
            Please be advised by answering YES to this question, your client will not be eligible to
            add Mental Health cover onto their policy when quoting for any AXA Health policies.
          </P>
        </Modal>
      )}
    </>
  )
}

AxaQuestionsTable.defaultProps = {}

AxaQuestionsTable.propTypes = {}

export default AxaQuestionsTable
