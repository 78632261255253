import { get } from "lodash"
import { boolean } from "yup"
import DisclosureNotes from "../../../../../../DisclosureNotes"
import NoteFormTemplate from "./noteFormTemplate"
import NoteDisplayTemplate from "./noteDisplayTemplate"
import Audit from "./Audit"

export const config = data => ({
  title: "Switch disclosures",
  sections: [
    {
      key: "section_1",
      components: [
        {
          component: Audit,
          skipDataMap: true
        },
        {
          key: "had_treatment_in_hospital_or_consulted_a_specialist_in_the_last_12_months",
          initialKey: "axa_anyone_received_treatment_or_consultation_in_last_12_months",
          label:
            "Have you or anyone to be covered under this plan, had treatment in hospital or consulted a specialist in the last 12 months?"
        },
        {
          key: "have_any_treatment_consultations_investigations_or_diagnostic_tests_planned_or_pending",
          initialKey: "axa_anyone_planned_or_pending",
          label:
            "Do you, or anyone else to be covered under this plan have any treatment, consultations, investigations, or diagnostic tests, planned or pending?"
        }
      ].map(item =>
        item.skipDataMap
          ? { ...item }
          : {
              ...item,
              name: item.key,
              initialValue: get(data, `payload.${item.key}`),
              validationSchema: boolean().required("MISSING_REQUIRED_FIELD"),
              component: DisclosureNotes,
              componentProps: {
                options: [
                  { order: 0, label: "No", value: false },
                  { order: 1, label: "Yes", value: true }
                ],
                noteFormTemplate: NoteFormTemplate,
                noteDisplayTemplate: NoteDisplayTemplate,
                isHorizontal: true,
                isRequired: true
              }
            }
      )
    }
  ]
})
