import React, { useEffect, useContext } from "react"
import { get, isEmpty } from "lodash"
import moment from "moment"
import { JourneyContext } from "../../../../../../journey.context"
import { BankDetails } from "../../../../../../../../Forms"

const CustomBankDetails = props => {
  const { data: journeyData, formik, updateJourneyAuditData } = useContext(JourneyContext)

  const address = get(formik, "values.bank_address", null)
  const accountNumber = get(formik, "values.account_number", null)
  const nameOnAccount = get(formik, "values.name_on_account", "")
  const sortCode = get(formik, "values.sort_code", "")

  const isExeter = get(journeyData, "journey.policy.provider.provider_key", false) === "EXETER"
  const declarationAgreedBy = get(formik, "values.exeter_direct_debit.declaration_agreed_by", "")
  const declarationAgreedAt = moment(
    get(formik, "values.exeter_direct_debit.declaration_agreed_at", "")
  ).format("DD/MM/YYYY")
  const preferredPaymentDay = get(formik, "values.exeter_direct_debit.preferred_payment_day", "")

  const isBupa = get(journeyData, "journey.policy.provider.provider_key", false) === "BUPA"
  const bupaTitle = get(formik, "values.bupa_direct_debit.title", "")
  const bupaFirstName = get(formik, "values.bupa_direct_debit.first_name", "")
  const bupaMiddleNames = get(formik, "values.bupa_direct_debit.middle_names", "")
  const bupaLastName = get(formik, "values.bupa_direct_debit.last_name", "")

  useEffect(() => {
    let appendExeterAudit = {}
    let appendBupaAudit = {}

    if (isExeter) {
      appendExeterAudit = {
        mode: "append",
        data: [
          {
            name: "Direct Debit Declaration Agreed By",
            value: declarationAgreedBy
          },
          {
            name: "Direct Debit Declaration Agreed At",
            value: declarationAgreedAt
          },
          {
            name: "Preferred Payment Day",
            value: preferredPaymentDay
          }
        ]
      }
    }

    if (isBupa) {
      appendBupaAudit = {
        mode: "append",
        data: [
          {
            name: "Payer Title",
            value: bupaTitle
          },
          {
            name: "Payer First Name",
            value: bupaFirstName
          },
          {
            name: "Payer Middle Names",
            value: bupaMiddleNames
          },
          {
            name: "Payer Last Name",
            value: bupaLastName
          }
        ]
      }
    }

    if (!isEmpty(address)) {
      updateJourneyAuditData([
        {
          mode: "remove",
          data: [{ name: "bankDetails" }]
        },
        appendExeterAudit,
        appendBupaAudit,
        {
          mode: "append",
          data: [
            {
              name: nameOnAccount,
              value: [
                {
                  name: "Account Number",
                  value: (() => {
                    const lastIndex = accountNumber.length - 1
                    return [...`${accountNumber || ""}`]
                      .map((acc, indx) => (indx < lastIndex - 3 ? "*" : acc))
                      .join("")
                  })()
                },
                {
                  name: "Account Sortcode ",
                  value: [...`${sortCode || ""}`].map(() => "*").join("")
                },
                {
                  name: "Bank Name",
                  value: get(address, "name", "")
                },
                {
                  name: "Bank Address",
                  value: [
                    get(address, "line_one", ""),
                    get(address, "line_two", ""),
                    get(address, "city", ""),
                    get(address, "county", ""),
                    get(address, "postcode", "")
                  ]
                    .filter(adr => !!adr)
                    .join(", ")
                }
              ]
            }
          ]
        }
      ])
    }

    return () => updateJourneyAuditData([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, formik.values])

  return <BankDetails {...props} />
}

export default CustomBankDetails
